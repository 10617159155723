import * as router from './modules/Router';
import parser from 'ua-parser-js';
import Vue from 'vue';
import Vuex from 'vuex';

const detectDevice = new parser();

Vue.use(Vuex);
const initialState = () => {
  return {
    jwt: '',
    loggedIn: false,
    profile: {
      phoneNumber: '',
      email: '',
      token: '',
      userUUid: '',
      widgetUuid: '',
      additionalData: '',
      name: '',
      surname: '',
      country: '',
      dob: '',
      kycVerified: false,
      kycInProgress: false,
      kycFailed: false,
    },
    order: {
      uuid: '',
      cryptoValidation: '',
      selectedFiat: ''
    },
    phazeOrder: {
      uuid: '',
      selectedCurrency: '',
      selectedCryptoCurrency: '',
      price: 0,
    },
    hideBackButton: false,
    hideMenuButton: false,
    pageQueue: [] as string[],
  };
};

export default new Vuex.Store({
  state: {
    errors: [] as string[],
    personalizedErrors: [] as object[],
    registerUser: {
      EmailVerified: false,
      PhoneVerified: false,
      KYCVerified: false,
      KYCComplete: false,
      EmailCode: '',
      PhoneCode: '',
    },
    config: {
      cryptoCurrencies: [],
      fiatCurrencies: [],
      minOrder: 50,
      css: '',
      showFees: true,
      twoFa: true,
      userManagement: true,
      twoFaEmailOnly: true,
      firstPage: '',
      transactionWaitingTimeInMin: 15,
      mobilumTrackingCode: '',
      trackingCode: '',
      fiatOnlyMode: false,
      features: '',
      sumsubUrl: '',
      menuContactUrl : '',
      menuCompanyUrl : '',
      pdfPath : '',
      privacyPolicyUrl : '',
      termsAndConditionsUrl : '',
      maxOrder: 0,
      defaultWalletAddress: ''
    },
    clientId: '', //GA cId
    apikey: '',
    passwordPattern: '',
    passwordStrength: 4,
    timeout: 10,
    lastFocus: 'Amount',
    userWallet: '',
    extraFieldsValues: [],
    selectedCryptoChain: '',
    selectedCryptoToken: null,

    backPage: '', //if != '' - back button will shown
    popup: '', //if != '' - popup with needed component will be shown
    countries: [],

    /*Current order and TX details*/
    currentTx: null,
    currentTxId: '',
    currentOrderWallet: '',
    prefillOrder: {} as {
      userWallet?: string;
      htmlFiatCurrency?: string;
      htmlFiatAmount?: number;
      htmlCryptoCurrency?: string;
      htmlCryptoAmount?: number;
      extraFieldsValues?: [any];
    },
    /*Payment Methods*/
    unsavedCard: null as any,
    editUnsaved: false,
    editSaved: false,
    editSavedId: -1,
    fromCards: false,
    addCard: false,
    userCards: [],
    canAddNewCard: false,
    defaultCardId: -1,
    selectedCardId: -1,
    //when operating with adding card details
    // + working with unsaved card
    card: {
      Number: '',
      MonthExpiry: '',
      YearExpiry: '',
      CVV2: '',
      SaveCard: false,
      cardId: -1,
    },
    paymentOption: '',

    swap: false,
    swapExchange: [{ id: 2, name: 'Sushiswap', value: 2 }],

    /*Phaze Gift Cards*/
    giftCardList: [],
    selectedGiftCardId: -1,
    selectedGiftCardValue: '',
    giftCardPage: 0,
    phazeCountries: [],
    selectedPhazeCountry: '' as string,
    phazePurchaseSuccess: false,
    currentPhazeOrderUuid: '',
    currentPhazePurchaseId : null,
    currentViewedCard: null,
    selectedPhazeCurrency: '',
    // TODO: Cart of selected gift cards - to confirm solution
    selectedGiftCardsCart: [] as any,
    giftCardDeliveryEmail: '',
    giftCadrOrderInf: undefined,

    // detect type of device
    isMobile: detectDevice.getDevice().type === 'mobile' ? true : false,
    ...initialState(),
  },
  mutations: {
    modifyLastFocus: (state, value) => {
      state.lastFocus = value;
    },
    modifyUserWallet: (state, value) => {
      state.userWallet = value;
    },
    modifyExtraFieldsValues: (state, value) => {
      state.extraFieldsValues = value;
    },
    modifyOrder: (state, payload) => {
      state.fromCards = true;
      state.prefillOrder = {
        userWallet: state.prefillOrder.userWallet,
        htmlCryptoCurrency: state.prefillOrder.htmlCryptoCurrency,
        extraFieldsValues: state.extraFieldsValues,
        ...payload,
      };
      //router.state.route = router.Routes.Exchange;
    },
    setPreviousPage: (state, value: string) => {
      if (!state.pageQueue.length) state.pageQueue.push(value);
      else if (state.pageQueue[state.pageQueue.length - 1] != value)
        state.pageQueue.push(value);
    },
    setPopup(state, value) {
      state.popup = value;
    },
    resumePurchase: state => {
      state.fromCards = false;
      state.prefillOrder = {
        userWallet: state.prefillOrder.userWallet,
        htmlCryptoCurrency: state.prefillOrder.htmlCryptoCurrency,
      };
      //router.state.route = router.Routes.PurchaseSummary;
    },
    errors(state, errors) {
      state.errors = errors;
    },
    personalizedErrors(state, errors) {
      state.personalizedErrors = errors;
    },
    resetRegister(state) {
      state.registerUser = {
        EmailVerified: false,
        PhoneVerified: false,
        KYCVerified: false,
        KYCComplete: false,
        EmailCode: '',
        PhoneCode: '',
      };
    },
    registerUser(state, payload) {
      state.registerUser = {
        ...state.registerUser,
        ...payload,
      };
    },
    profile(state, payload) {
      state.profile = {
        ...state.profile,
        ...payload,
      };
    },
    clientId(state, payload) {
      state.clientId = payload;
    },
    order(state, payload) {
      state.order = { ...state.order, ...payload };
    },
    phazeOrder(state, payload) {
      state.phazeOrder = { ...state.phazeOrder, ...payload };
    },
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    resetCartState({ commit }) {
      commit('resetState');
    },
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
    isProfileFilled: state =>
      state.profile.name != '' &&
      state.profile.surname != '' &&
      state.profile.country != '' &&
      state.profile.dob != '',
    popup: state => state.popup,
  },
  modules: {
    router,
  },
});
