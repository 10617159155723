import { Routes } from './../store/modules/Router';
import axios from 'axios';
import store from '@/store';
import { IBasketOrderItem } from '@/components/Common/interfaces/IBasketOrderItem';
import { PostRegisterUserDTO } from './interfaces';

const BASE_URL = process.env.VUE_APP_BASE_URL;

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'aplication/json',
  },
});

const auth = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

auth.interceptors.request.use(config => {
  config.headers.apikey = store.state.apikey;
  config.headers.WidgetUuid = store.state.profile.widgetUuid;
  config.headers.additionalData = store.state.profile.additionalData;
  config.headers.authorization = store.state.jwt;
  return config;
});

auth.interceptors.response.use(
  response => response,
  error => {
    // if fiatOnlyMode = true, do nothing
      console.log("Error on resp:", error)
    if (!store.state.config.fiatOnlyMode && error.response.status === 401) {
      store.dispatch('resetCartState').then(() => {
        store.commit('errors', [error.response.data.errorCode]);
        setTimeout(() => store.commit('errors', []), 2000);
        store.commit('router/goTo', Routes.JwtExpired);
      });
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use(config => {
  config.headers.apikey = store.state.apikey;
  return config;
});

export const getBestPrice = async (symbol: string, amount: number, maxPrice : number, swapToken: string | null, swapExchange: string | null) =>
  api.get('/marketdata/bestprice/', {
    params: {
      symbol,
      amount,
      swapToken,
      swapExchange,
      maxPrice
    },
  });

export const getBestPriceByPrice = async (symbol: string, price: number, maxPrice : number, swapToken: string | null, swapExchange: string | null) =>
  api.get('/marketdata/QuantityForPrice/', {
    params: {
      symbol,
      price,
      swapToken,
      swapExchange,
      maxPrice
    },
  });

export const sendAnalytics = async (body: { EventCategory: string, EventAction: string, EventLabel: string }) =>
  auth.post('/widget/Analytics/', { ...body, ClientId: store.state.clientId });

export const getCardsForUser = async (userId: string) =>
  auth.get('/card/ListCardsDetails/', {
    params: {
      userId: userId
    }
  }).then((response) => {
    if (response && response.data) {
      const cards = response.data.cards;
      store.state.userCards = cards;
      store.state.canAddNewCard = response.data.canAddNewCard;
      let gotDefault = false;
      for (let i = 0; i < cards.length; i++) {
        if (cards[i].isDefault) {
          gotDefault = true;
          store.state.defaultCardId = cards[i].cardId;
          store.state.selectedCardId = cards[i].cardId;
        }
      }
      if (!gotDefault && cards.length > 0) {
        store.state.defaultCardId = cards[0].cardId;
        store.state.selectedCardId = cards[0].cardId;
      } else if (store.state.unsavedCard != null) {
        store.state.defaultCardId = -2;
      }
    }
  });

export const getUserTransactions = async (userId: string) =>
  auth.get('/widget/GetUserTransactions/', {
    params: {
      userUuid: userId,
      deliveryCategory: null
    }
  });//GetTransactionDetails


export const validateCardCheckout = async (userId: string, cardNumber: string) =>
  auth.get('/widget/ValidateCardCheckout/', {
    params: {
      userUUID: userId,
      cardNumber: cardNumber,
      isCardSaved: false
    }
  });

export const getTransactionDetailsForHistory = async (requestUuid: string) =>
  auth.get('/widget/GetTransactionDetails/', {
    params: {
      requestUuid: requestUuid
    }
  });

export const saveBillingAddress = async (body: any, params: any) =>
  auth.post('/card/SaveBillingAddress/', body, { params: params });

export const addPaymentMethod = async (body: any, AddressId: string) =>
  auth.post('/widget/AddPaymentMethod/', body, { params: { userUUid: store.state.profile.userUUid, billingAddressId: parseInt(AddressId) } });

export const updatePaymentMethod = async (body: any, AddressId: string) =>
  auth.patch('/widget/UpdatePaymentMethod/', body, { params: { userUUid: store.state.profile.userUUid, billingAddressId: parseInt(AddressId) } });

export const deleteCard = async () =>
  auth.get('/card/DeleteCard/', { params: { userId: store.state.profile.userUUid, cardId: store.state.card.cardId } });

export const loginUser = async (email: string) =>
  auth.get('/widget/LoginUserEmail/', {
    params: {
      email: email
    }
  });

export const forgotPassword = async (email: string) =>
  auth.post('/widget/ForgotUserPassword/', null, {
    params: {
      email,
    },
  });

export const registerUser = async (body: PostRegisterUserDTO) =>
  auth.post('/widget/RegisterUser/', body);

export const checkEmailExists = (email: string) =>
  auth.get('/widget/CheckEmailExist/', {
    params: {
      email,
    },
  });

export const updateUserDetails = (body: any) =>
  auth.patch('/Widget/UpdateUser/', body, {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const requestEmailCode = () =>
  auth.post('/Utils/SendConfirmationCodeByMail/', null, {
    params: {
      email: store.state.profile.email,
    },
  });

export const requestEmailCodeByEmail = () =>
  auth.post('/Utils/SendConfirmationCodeByMail/', null, {
    params: {
      email: store.state.profile.email,
    },
  });

export const RegisterUserByStep = (
  body: {
    Name?: string,
    Surname?: string,
    Email?: string,
    Password?: string,
    RePassword?: string,
    Address?: string,
    PhoneNumber?: string,
    PhoneNumberVerificationCode?: string,
    EmailVerificationCode?: string,
    Country?: string,
    StepId?: string
  }

) =>
  auth.post('/Widget/RegisterUserByStep', body);

export const registerResendPhone = (stepId: string) =>
  auth.post('/Widget/RegisterUserByStep/ResendPhone/', {
    stepId: stepId
  },
    {
      params: {
        stepId: stepId,
      }
    });

export const registerResendEmail = (stepId: string) =>
  auth.post('/Widget/RegisterUserByStep/ResendEmail/', {
    stepId: stepId
  },
    {
      params: {
        stepId: stepId,
      }
    });


export const requestSMSCode = () =>
  auth.post('/Utils/SendConfirmationCodeBySMS/', null, {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const verifyEmailCode = (code: string) =>
  auth.get('/Widget/VerifyEmailCode/', {
    params: {
      userUUid: store.state.profile.userUUid,
      code,
    },
  });

export const VerifyEmailCodeByEmail = (code: string) =>
  auth.get('/Widget/VerifyEmailCodeByEmail/', {
    params: {
      email: store.state.profile.email,
      code,
    },
  });

export const verifyPhoneCode = (code: string) =>
  auth.get('/Widget/VerifyPhoneCode/', {
    params: {
      userUUid: store.state.profile.userUUid,
      code,
    },
  });

export const verifyAnyCode = (code: string) =>
  auth.get('/Widget/VerifyAnyCode/', {
    params: {
      email: store.state.profile.email,
      code,
    },
  });

export const performKyc = () =>
  auth.get('/KYC/PerformKyc/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const checkKyc = () =>
  auth.get('/kyc/GetUserKycStatusInProgress/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const isKycComplete = () =>
  auth.get('/kyc/GetUserKycCompletedStatus/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const checkKycVerified = (orderId : string) =>
  auth.get('/Widget/CanPay/', {
    params: {
      userUUid: store.state.profile.userUUid,
      orderUuid: orderId//store.state.order.uuid || store.state.phazeOrder.uuid
    },
  });
export const shouldPerformKyc = () =>
  auth.get('/KYC/ShouldPerformKyc/', {
    params: {
      userUUid: store.state.profile.userUUid
    },
  });

export const StartKyc = () =>
  auth.get('/KYC/StartKyc/', {
    params: {
      userid: store.state.profile.userUUid
    },
  });

export const checkTransaction = (
  body: {
    Number?: string;
    MonthExpiry?: string;
    YearExpiry?: string;
    CVV2?: string;
    SaveCard?: boolean;
    PreSavedCard?: string;
  },
  query: {
    name?: string;
    cardAuthenticationId?: string;
  },
) =>
  auth.post('/Widget/CheckTransaction', body, {
    params: {
      userUUid: store.state.profile.userUUid,
      ...query,
    },
  });

export const processPayment = (
  body: {
    Number?: string;
    MonthExpiry?: string;
    YearExpiry?: string;
    CVV2?: string;
    SaveCard?: boolean;
    PreSavedCard?: string;
  },
  query: {
    name?: string;
  },
) =>
  auth.post('/Widget/ProcessPayment/', body, {
    params: {
      userUUid: store.state.profile.userUUid,
      ...query,
    },
  });

export const processPayment3DSRequest = (
  body: {
    BrowserLanguage?: string;
    BrowserScreenHeight?: string;
    BrowserScreenWidth?: string;
    BrowserTimeZone?: string;
    BrowserUserAgent?: string;
    BrowserScreenColorDepth?: string;
    BrowserJavaEnabledVal?: string;
    BrowserJavaScriptEnabled?: string;
    Number?: string;
    MonthExpiry?: string;
    YearExpiry?: string;
    CVV2?: string;
    SaveCard?: boolean;
    PreSavedCard?: string;
  },
  query: {
    name?: string;
  },
) =>
  auth.post('/Widget/ProcessPaymentNew/', body, {
    params: {
      userUUid: store.state.profile.userUUid, 
      paymentOption: store.state.paymentOption,
      billingAddressId : store.state.unsavedCard?.addressId,
      ...query,
    },
  });

export const ProcessPaymentNewVolt = (orderUuid : string
) =>
  auth.post('/Widget/ProcessPaymentNewVolt/', null, {
    params: {
      userUUid: store.state.profile.userUUid,
      orderUuid : orderUuid,
    },
  });

export const AuthenticateTx = (
  body: {
    BrowserLanguage?: string;
    BrowserScreenHeight?: string;
    BrowserScreenWidth?: string;
    BrowserTimeZone?: string;
    BrowserUserAgent?: string;
    BrowserScreenColorDepth?: string;
    BrowserJavaEnabledVal?: string;
    BrowserJavaScriptEnabled?: string;
    Number?: string;
    MonthExpiry?: string;
    YearExpiry?: string;
    CVV2?: string;
    SaveCard?: boolean;
    PreSavedCard?: string;
  },
  query: {
    name?: string;
    cardAuthenticationId?: string;
  },
) =>
  auth.post('/Widget/AuthenticateTx/', body, {
    params: {
      userUUid: store.state.profile.userUUid,
      ...query,
    },
  });

export const gerOrderDetails = (
  body: {
    cardNumber : string | number | null;
    cardId : string | number | null;
  }
) =>
  auth.post('/widget/order-details/'+store.state.order.uuid, {
    cardNumber : body.cardNumber,
    cardId : body.cardId,
    addressId: store.state.unsavedCard?.addressId
  }, {});

export const getTransactionDetails = (transactionUuid: string) =>
  auth.get('/Widget/IsTransactionComplete/', {
    params: {
      transactionUuid,
    },
  });

export const getTransactionStatus = (transactionUuid: string) =>
  auth.get('/Widget/GetUpdatedTransactionStatus/', {
    params: {
      transactionUuid,
    },
  });


export const processPaymentNewAqua = (
  body: {
    OrderUuid: string;
    UserUuid: string;
    CryptoType: string;
    Fiat: string;
    NetworkType: string;
  }
) =>
  auth.post('/Widget/ProcessPaymentNewAqua/', body);

export const createOrder = (body: object, email: string | null) => {
  const params = {} as any;

  if (store.state.profile.userUUid) params.userUUid = store.state.profile.userUUid;
  params.userEmail = email;

  return auth.post('/widget/AddOrder/', body, {
    params: params
  })
};

export const getGlobalConfiguration = () =>
  auth.get('/Configuration/GetGlobalConfiguration/');

export const getCountries = () => auth.get('/Configuration/GetCountries/');

export const getPhazeCountries = () => auth.get('/Delivery/ListAvailableCountries/', {
  params: {
    category: "Phaze"
  }
});
export const getPhazeOrder = (orderUUid: string | null) => auth.get('/Delivery/ListPhazeDeliveryDetails/', {
  params: {
    orderUUId: orderUUid,
    userUuid: store.state.profile.userUUid
  }
});
export const CheckTransactionAqua = (purchaseUuid: string) => auth.get('/Widget/CheckTransactionAqua/', {
  params: {
    purchaseUuid: purchaseUuid,
  }
});
export const CheckPendingTransactionAqua = (orderUuid: string) => auth.get('/Delivery/HasGiftCardsInPendingState/', {
  params: {
    orderUUID: orderUuid,
  }
});

export const recoverPassword = (body: {
  password: string;
  email: string;
  phoneCode: string;
  emailCode: string;
}) => auth.post('/widget/ResetUserPassword/', null, { params: body });

export const recoverPasswordEmailOnly = (body: {
  password: string;
  email: string;
  code: string;
}) => auth.post('/widget/ResetUserPasswordEmailOnly/', null, { params: body });

export const validateRID = (rid: string) =>
  auth.get('/widget/CheckPasswordResetID/', { params: { rid } });

export const ListAvailableDeliveries = () =>
  auth.get('/Delivery/ListAvailableDeliveries/', { params: { category: "Phaze", country: store.state.selectedPhazeCountry } });

export const GetDeliveryByProductId = (productId: string) =>
  auth.get(`/Delivery/GetDeliveryByProductId`, { params: { productId: productId } });

export const getUserNameAsString = () =>
  auth.get('/widget/GetUserNameAsString/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const getUserProfileData = () =>
  auth.get('/widget/GetUserProfileData/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  }).then((response) => {
    if (response && response.data && response.data.success) {
      store.state.profile.name = response.data.result.name;
      store.state.profile.surname = response.data.result.surname;
      store.state.profile.country = response.data.result.country;
      store.state.profile.dob = response.data.result.dob;
      store.state.profile.kycVerified = response.data.result.kycVerified;
      store.state.profile.kycInProgress = response.data.result.kycInProgress;
      store.state.profile.kycFailed = response.data.result.kycFailed;
    }
  });

export const getUserCards = () =>
  auth.get('/widget/getCardsforuser/', {
    params: {
      userUUid: store.state.profile.userUUid,
    },
  });

export const uploadScreenshot = (screenShot: Blob) => {
  const formdata = new FormData();

  formdata.append('screenShot', screenShot);

  return auth.post('/Widget/UploadConfirmationScreenshot/', formdata, {
    params: {
      orderUuid: store.state.order.uuid || store.state.phazeOrder.uuid,
      userUUid: store.state.profile.userUUid,
    },
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
};

//#region ___ Basket order services ___

export const createBasketOrderService = (order: IBasketOrderItem[]) =>
  auth.post(`/order`, order);

// TODO: Redirect to correct endpoint
export const updateBasketOrderService = (order: IBasketOrderItem[]) =>
  auth.post(`/order`, order);

//#endregion ___ Basket order services ___