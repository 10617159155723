import { render, staticRenderFns } from "./Exchange.vue?vue&type=template&id=f3df8ffc&scoped=true"
import script from "./Exchange.vue?vue&type=script&lang=ts"
export * from "./Exchange.vue?vue&type=script&lang=ts"
import style0 from "./Exchange.vue?vue&type=style&index=0&id=f3df8ffc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3df8ffc",
  null
  
)

export default component.exports