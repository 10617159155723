<template>
  <div class="MBMWidget_Phaze_Card_Pending MBMWidget_mx-auto MBWidget_block">
    
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBMWidget_Phaze_Card_Success_Block">
      <span class="loader"></span>
      <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 ">
        <div class="MBMWidget_title MBMWidget_Header MBMWidget_mb-4">
          {{ $t('phaze_card_pending_delivery_title') }}
        </div>
        
        <div class="MBMWidget_subtitle MBMWidget_text-center" style="text-align: center !important;">
          {{ $t('phaze_card_pending_delivery_subtitle') }}
        </div>
      </div>
    </div>
    <div class="MBMWidget_col-np-12 MBMWidget_col-np-xs-12 MBWidget_footer_actions MBMWidget_px-auto">
      <div class="MBMWidget_col-12 MBMWidget_col-xs-12 MBMWidget_mx-auto MBWidget-nofloat">
        <AppButton
          class="MBMWidget_full-width MBMWidget_defaultAppButton"
          color="primary"
          @click="buyMore"
        >
          {{ $t('phaze_card_purchased_button') }}
        </AppButton>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
  import Vue from "vue";
  import store from "@/store";
  import Button from "@/components/General/Button.vue";
  import {CheckPendingTransactionAqua, CheckTransactionAqua} from "@/api";

  export default Vue.extend({
    components: {
      AppButton: Button,
    },
    data() {
      return {
        store,
        loading: true,
        checkTxInterval: null as any,
        unconfirmedAmount : '',
        crypto : '',
        pendingDone : false
      }
    },
    methods: {
      buyMore() {
        this.$store.commit('router/goTo', 'PhazeCardList');
      },
      async checkTx() {
        const response = await CheckPendingTransactionAqua(this.$store.state.phazeOrder.uuid);
        if (response.data == false) {
          //this.$store.commit('router/goTo', 'PhazeCardPendingPayment');
          this.routeToSuccess();
        }
      },
      cleanup() {
        this.$store.state.currentPhazeOrderUuid =
          this.$store.state.phazeOrder.uuid;
        this.$store.commit('phazeOrder', { uuid: '' });
        this.$store.state.giftCardList = [];
        this.$store.state.selectedGiftCardId = -1;
        this.$store.state.selectedGiftCardValue = '';
        this.$store.state.giftCardPage = 0;
        this.$store.state.phazeCountries = [];
        this.$store.state.selectedGiftCardsCart = [] as any;
        this.$store.state.giftCardDeliveryEmail = '';
        this.$store.state.selectedPhazeCountry = '' as string;
        this.$store.state.selectedPhazeCurrency = '' as string;
      },
      routeToSuccess() {
        this.cleanup();

        setTimeout(() => {
          this.$store.state.phazePurchaseSuccess = true;
          this.$store.commit('router/goTo', 'PhazeCardPurchased');
        }, 8000);
      },
    },
    mounted() {
      console.log("We got to pending delivery!")
      this.$store.state.hideBackButton = true;
      this.$store.state.hideMenuButton = true;
      this.$store.state.pageQueue = [] as string[];
      this.$store.commit('setPreviousPage', 'PhazeCardList');
      this.$store.commit('setPreviousPage', 'PhazeCardList');

      this.checkTxInterval = setInterval(async () => {
        this.checkTx();
      }, 5000);
    },
    beforeDestroy() {
      this.$store.state.hideBackButton = false;
      this.$store.state.hideMenuButton = false;
      this.cleanup();
    }
  });
</script>

<style scoped>
  
</style>