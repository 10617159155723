import Vue from 'vue';
import { getGlobalConfiguration, registerUser } from './api';
import App from './App.vue';
import VueGtag from "vue-gtag";
import store from './store';
import { Routes } from './store/modules/Router';
import VueObserveVisibility from 'vue-observe-visibility'
import {getCookie, redirectViewString} from '@/utils';
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

import VueI18n from 'vue-i18n';
import { languages, defaultLocale } from './i18n/index';
import VueGtagPlugin from "vue-gtag";
import {VTooltip} from "v-tooltip";
import { Buffer } from 'buffer';
import process from 'process';


const messages = Object.assign(languages)



Vue.config.productionTip = false;
// @ts-ignore
window.process = process;
// @ts-ignore
window.Buffer = Buffer;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    environment: process.env.VUE_APP_BRANCH,
    release: 'mobilum_' + process.env.VUE_APP_VERSION,
  });
}

Vue.use(VueI18n);

const language = defaultLocale;

/*try {
  language = window.navigator.language.substring(0, 2)
  if (!Object.keys(languages).includes(language)) {
    language = defaultLocale
  }
} catch (error) {
  language = defaultLocale
}*/

const i18n = new VueI18n({
  locale: language,
  messages
})


Vue.directive('tooltip', VTooltip);


function resolveVueInstance() {
  getGlobalConfiguration().then(response => {
    store.state.config = response.data.result.config;
    const styles = document.getElementById('mobilum-widget-css');
    if (styles) {
      styles.innerHTML = store.state.config.css;
    }
    const blockedRegion = response.data.result?.RegionName;
    store.commit('profile', {
      blockedRegion: blockedRegion
    });
    
    const mobilumTrackingCode = store.state.config.mobilumTrackingCode;
    const siteTrackingCode = store.state.config.trackingCode;
    const options : any = {
      config: { 
        id: mobilumTrackingCode,
        params: {
          'cookie_flags': 'SameSite=None;Secure'
        },
      },
      globalObjectName: 'G_WID',
    };
    if(siteTrackingCode != null && siteTrackingCode != '') {
      options['includes'] = [
        { id: siteTrackingCode },
      ]
    }
    Vue.use(VueGtag, options);
    
    if(!store.state.config.fiatOnlyMode && store.state.profile.userUUid != '')
      store.state.loggedIn = true;
    
    let clientId = getCookie('_ga').slice(6);//remove prefix and version from GA client Id
    if(clientId === undefined || clientId == '')
      clientId = store.state.profile.widgetUuid;
    
    /*let clientId = store.state.profile.widgetUuid;
    if(clientId === undefined || clientId == '')
      clientId = '1234567890';*/
    store.commit('clientId', clientId);
    if(blockedRegion !== undefined && blockedRegion != null && blockedRegion != ''){
      redirectViewString('BlockedRegion');
    } else {
      redirectViewString(store.state.config.firstPage);
    }
    
	  //store.commit('router/goTo', 'RegionBlocked'); 
    new Vue({
      i18n,
      store,
      render: h => h(App),
    }).$mount('#mobilum-widget');
  });
}

Vue.use(VueObserveVisibility)

const parent = document.getElementById('mobilum-widget');
const s : URLSearchParams = new URL(window.location.href).searchParams;

if (parent) {
  const { apikey, passwordStrength, timeout} = parent.dataset;


  store.state.jwt = parent.getAttribute('jwt-token') || '';
  // @ts-ignore
  store.state.apikey = s.get('apikey') ? s.get('apikey') :  apikey || process.env.VUE_APP_API_KEY;
  store.state.passwordStrength = s.get('passwordStrength') ? Number(s.get('passwordStrength')) : Number(passwordStrength) || 2;
  store.state.timeout = s.get('timeout') ? Number(s.get('timeout')) : Number(timeout) || 1;
}

const cryptoCurrency = document.querySelector(`#mobilum-widget #cryptoCurrency`)
  ?.innerHTML;
(store.state.prefillOrder as {
  htmlCryptoCurrency?: string; 
  // @ts-ignore
}).htmlCryptoCurrency = s.get('cryptoCurrency') ? s.get('cryptoCurrency').toString() : cryptoCurrency || '';

const walletAddress = document.querySelector(`#mobilum-widget #walletAddress`)
  ?.innerHTML;
(store.state.prefillOrder as { userWallet?: string }).userWallet =
  walletAddress || '';
// @ts-ignore
store.state.userWallet = s.get('walletAddress') ? s.get('walletAddress').toString() : walletAddress || '';

const fiatCurrency = document.querySelector(`#mobilum-widget #fiatCurrency`)
  ?.innerHTML;
(store.state.prefillOrder as { htmlFiatCurrency?: string }).htmlFiatCurrency =
  // @ts-ignore
  s.get('fiatCurrency') ? s.get('fiatCurrency').toString() : fiatCurrency || '';

const fiatAmount = document.querySelector(`#mobilum-widget #fiatAmount`)
  ?.innerHTML;
(store.state.prefillOrder as { htmlFiatAmount?: string }).htmlFiatAmount =
  // @ts-ignore
  s.get('fiatAmount') ? s.get('fiatAmount').toString() : fiatAmount || '';

const cryptoAmount = document.querySelector(`#mobilum-widget #cryptoAmount`)
  ?.innerHTML;
(store.state.prefillOrder as { htmlCryptoAmount?: string }).htmlCryptoAmount =
  // @ts-ignore
  s.get('cryptoAmount') ? s.get('cryptoAmount').toString() : cryptoAmount || '';

const userUUid = document.querySelector(`#mobilum-widget #userUUid`)?.innerHTML;

// @ts-ignore
store.state.profile.userUUid =  s.get('userUUid') ? s.get('userUUid') : userUUid || '';

const widgetUuid = document.querySelector(`#mobilum-widget #widgetUuid`)?.innerHTML;

// @ts-ignore
store.state.profile.widgetUuid = s.get('widgetUuid') ? s.get('widgetUuid') :  widgetUuid || '';

const additionalData = document.querySelector(`#mobilum-widget #additionalData`)?.innerHTML;

// @ts-ignore
store.state.profile.additionalData = s.get('additionalData') ? s.get('additionalData') :  additionalData || '';

let register = true;

let clientData = ['', '', '', '', '', '', ''];

if (!userUUid) {
  const fields = [
    'name',
    'surname',
    'address',
    'password',
    'phone',
    'email',
    'country',
  ];

  clientData = fields.map(
    field =>
      document.querySelector(`#mobilum-widget #${field}`)?.innerHTML || '',
  );
// @ts-ignore
  if(s.get('name')) clientData[0] = s.get('name');
// @ts-ignoreif(s.get('surname')) clientData[0] = s.get('surname');
  if(s.get('address')) clientData[0] = s.get('address');
// @ts-ignore
  if(s.get('password')) clientData[0] = s.get('password');
// @ts-ignore
  if(s.get('phone')) clientData[0] = s.get('phone');
// @ts-ignore
  if(s.get('email')) clientData[0] = s.get('email');
// @ts-ignore
  if(s.get('country')) clientData[0] = s.get('country');

  for (let index = 0; index < clientData.length; index++) {
    const element = clientData[index];
    if (!element) {
      register = false;
    }
  }
} else {
  register = false;
}

if (register) {
  registerUser({
    Name: clientData[0] || '',
    Surname: clientData[1] || '',
    Address: clientData[2] || '',
    Password: clientData[3] || '',
    PhoneNumber: clientData[4] || '',
    Email: clientData[5] || '',
    Country: clientData[6] || '',
    Imported: true,
  })
    .then(response => {
      store.commit('profile', response.data);
      store.commit('router/goTo', Routes.Login);
    })
    .finally(() => {
      resolveVueInstance();
    });
} else {
  const orderUUid = s.get('orderUUid') ? s.get('orderUUid') : document.querySelector(`#mobilum-widget #orderUUid`)?.innerHTML;

  if (orderUUid) {
      store.commit('order', { uuid: orderUUid });
  }

  resolveVueInstance();
}
